<template>
  <div>
    <div class="tile is-parent pl-1">
      <div class="tile is-child box">

        <div class="tabs">
          <ul>
            <li
              :class="{ 'is-active' : subComponent === 'OrmQdeVersionChange' }">
              <a @click="subComponent = 'OrmQdeVersionChange'">QDE Version Change</a>
            </li>
          </ul>
        </div>
        <div v-if="subComponent === 'OrmQdeVersionChange'">
          <div class="subtitle">Send QDE Version Change 3.00 to ORM</div>
          <label class="label">Repairer ID</label>
          <div class="field is-grouped">
            <div class="control">
              <input type="text"
                class="input"
                :class="{ 'is-danger': $v.repairerId.$error }"
                style="max-width: 12em"
                placeholder="Repairer ID"
                v-model="repairerId">
            </div>
            <div class="control">
              <button class="button is-primary"
                @click="sendQdeVersionChange">Send</button>
            </div>
          </div>
          <span v-if="$v.repairerId.$error"
            class="help is-danger">Please enter a valid Repairer ID</span>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CompanyOrmUtilities',
  components: {
  },
  validations: {
    repairerId: {
      required
    }
  },
  mixins: [],
  props: {
    value: null
  },
  data: () => {
    return {
      companySetting: null,
      subComponent: 'OrmQdeVersionChange',
      repairerId: ''
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    ...mapActions('company', ['getCompanySetting']),
    async sendQdeVersionChange() {
      this.$v.repairerId.$touch()
      if (this.$v.repairerId.$error) {
        return
      }
      try {
        await OrmService.sendQdeVersionChange(this.repairerId)
        this.$toast.open({
          message: 'QDE Version Change sent successfully.',
          type: 'is-success',
          duration: 5000
        })
      } catch (e) {
        this.isActive = false
        this.$emit('update:active', false)
        this.$toast.open({
          message: `${e.message}`,
          type: 'is-danger',
          duration: 5000
        })
      }
      //
      // console.log(result)
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.list-style-reset {
  margin-top: 1em;
  li {
    list-style: initial;
    list-style-type: disc;
    margin-left: 2em;
  }
}
</style>